export const environment = {
  production: true,
  api_url: 'https://appsv2.digivive.com/zonettv/',
  play_url:'https://appsv2.digivive.com/',
  // googleTrackingCode: 'UA-180910812-1',//stage.demo
  googleTrackingCode: 'G-758PVHN5EZ', // zonet
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
  api_base_url:'https://appsv2.digivive.com/production/',
};